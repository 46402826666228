import { renderIcon } from '@/utils/index'
import { CloudCircleSharp } from '@vicons/ionicons5'

const role = () => import('@/views/system/role.vue')
export default {
  path: 'role',
  name: 'system_role',
  component: role,
  meta: {
    title: '角色管理',
    icon: renderIcon(CloudCircleSharp),
    rtype: 'item',
    sort: 2
  }
}
