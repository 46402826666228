import { renderIcon } from '@/utils/index'
import { BusinessOutline } from '@vicons/ionicons5'

export default {
  path: 'eaanaly05',
  name: 'eaanaly_eaanaly05',
  component: () => import('@/views/eaAnaly/eaAnaly05.vue'),
  meta: {
    title: 'Won Opportunities',
    icon: renderIcon(BusinessOutline),
    rtype: 'item',
    sort: 3
  }
}
