import { createStore, Store, useStore as useVuexStore } from 'vuex'
import { IRootState, IStoreType } from '@/types'

import login from './login/login'

const store = createStore<IRootState>({
  state: () => {
    return {
      name: 'simon',
      age: 18
    }
  },
  mutations: {
    // changeName(state) {
    //   state.name = 'lsm'
    // }
  },
  getters: {},
  actions: {},
  modules: {
    login
  }
})

// 防止刷新后 store数据丢失
export function setupStore() {
  // console.log('初始化store')
  store.dispatch('login/loadLocalLogin')
}

// 修复 vuex 与 typescript 不兼容
export function useStore(): Store<IStoreType> {
  return useVuexStore()
}
export default store
