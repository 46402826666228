import { renderIcon } from '@/utils/index'
import { AccessibilitySharp } from '@vicons/ionicons5'

export default {
  path: 'bp01',
  name: 'bp_bp01',
  component: () => import('@/views/BP/bp01.vue'),
  meta: {
    title: '客户创建',
    icon: renderIcon(AccessibilitySharp),
    rtype: 'item',
    sort: 1
  }
}
