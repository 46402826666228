import './assets/css/index.less'
import './assets/css/tailwind.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { setupStore } from './store'
import { globalRegister } from './global'

function bootstrap() {
  // const appProvider = createApp(AppProvider)
  const app = createApp(App)

  //注册全局组件
  app.use(globalRegister)

  app.use(store)
  setupStore()
  app.use(router)

  // appProvider.mount('#appProvider', true)

  // app.mount('#app', true)
  app.mount('#app')
}

void bootstrap()

// console.log(process.env.NODE_ENV)
// console.log(process.env.VUE_APP_API)

if (process.env.VUE_APP_MOCK) {
  require('./mock/index')
  console.log('mock...', process.env.VUE_APP_MOCK)
}

// console.log(router.getRoutes())

// import qlRequest from './service'
// qlRequest
//   .post<any>({
//     url: '/login',
//     data: { name: 'simon', password: '123456' },
//     showLoading: true
//   })
//   .then((res) => {
//     console.log(res)
//   })
