import axios from 'axios'
import type { AxiosInstance } from 'axios'
import type { QLRequestInterceptors, QLRequestConfig } from '@/types'

import { ElLoading } from 'element-plus'
// import { ILoadingInstance } from 'element-plus/lib/components/loading/src/loading.type'
import { LoadingInstance } from 'element-plus/es/components/loading/src/loading'

const DEAFULT_LOADING = true

class QLRequest {
  instance: AxiosInstance
  interceptors?: QLRequestInterceptors
  showLoading: boolean
  // loading?: ILoadingInstance
  loading?: LoadingInstance

  constructor(config: QLRequestConfig) {
    // 创建axios实例
    this.instance = axios.create(config)
    // debugger
    // 保存基本信息
    this.showLoading = config.showLoading ?? DEAFULT_LOADING
    this.interceptors = config.interceptors

    // 使用拦截器
    // 1.从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )

    // 2.添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        if (this.showLoading) {
          this.loading = ElLoading.service({
            lock: true,
            text: '玩命加载中....',
            background: 'rgba(0, 0, 0, 0.5)'
          })
        }
        return config
      },
      (err) => {
        return err
      }
    )

    // 响应拦截
    this.instance.interceptors.response.use(
      (res) => {
        // 将loading移除
        // 判断是否mock环境 模拟后台更新
        if (process.env.VUE_APP_MOCK) {
          setTimeout(() => {
            this.loading?.close()
          }, 2000)
        } else {
          this.loading?.close()
        }

        // console.log('service response:', res)

        return res.data
      },
      (err) => {
        // 将loading移除
        this.loading?.close()

        return err
      }
    )
  }

  request<T = any>(config: QLRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      // 1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }

      // 2.判断是否需要显示loading
      if (config.showLoading === false) {
        this.showLoading = config.showLoading
      }

      this.instance
        .request<any, T>(config)
        .then((res) => {
          // 1.单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 2.将showLoading设置true, 这样不会影响下一个请求
          this.showLoading = DEAFULT_LOADING
          // console.log('res2', res)

          // 3.将结果resolve返回出去
          resolve(res)
        })
        .catch((err) => {
          // 将showLoading设置true, 这样不会影响下一个请求
          // console.log('err3', err)

          this.showLoading = DEAFULT_LOADING
          reject(err)
          return err
        })
    })
  }

  get<T = any>(config: QLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'GET' })
  }

  post<T = any>(config: QLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'POST' })
  }

  delete<T = any>(config: QLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'DELETE' })
  }

  patch<T = any>(config: QLRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: 'PATCH' })
  }
}

export default QLRequest
