import { renderIcon } from '@/utils/index'
import { AccessibilitySharp } from '@vicons/ionicons5'

export default {
  path: 'bp04',
  name: 'bp_bp04',
  component: () => import('@/views/BP/bp04.vue'),
  meta: {
    title: '全量客户查询',
    icon: renderIcon(AccessibilitySharp),
    rtype: 'item',
    sort: 4
  }
}
