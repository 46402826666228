import qlRequest from '../index'

import { ILoginResult, IDataType, IAccount, IUserToken } from '@/types'
// import { LoginAPI, RoleAPI } from '@/enums'
import { LoginAPI } from '@/enums'

export function accountLoginRequest(account: IAccount) {
  return qlRequest.post<IDataType<ILoginResult>>({
    url: LoginAPI.AccountLogin,
    data: account
  })
}

export function requestUserInfo(userInfo: IUserToken) {
  return qlRequest.post<IDataType>({
    url: LoginAPI.LoginUserInfo,
    data: { userid: userInfo.userid },
    headers: { token: userInfo.token },
    showLoading: false
  })
}

export function requestUserMenus(userInfo: IUserToken) {
  return qlRequest.post<IDataType>({
    url: LoginAPI.UserMenus,
    data: { userid: userInfo.userid, type: userInfo.type },
    headers: { token: userInfo.token },
    showLoading: false
  })
}

export function requestUserToken(userToken: IUserToken) {
  return qlRequest.post<IDataType>({
    url: LoginAPI.CheckToken,
    data: {
      userid: userToken.userid,
      token: userToken.token,
      type: userToken.type
    },
    showLoading: false
  })
}

// // 获取用户角色
// export function requestUserRole(userToken: IUserToken) {
//   return qlRequest.post<IDataType>({
//     url: RoleAPI.UserRole,
//     data: {
//       userid: userToken.userid,
//       token: userToken.token,
//       type: userToken.type
//     },
//     showLoading: false
//   })
// }
