import { renderIcon } from '@/utils/index'
import { SettingsSharp } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/usersetting',
  name: 'usersetting',
  component: Layout,
  meta: {
    title: 'User Settings',
    icon: renderIcon(SettingsSharp),
    rtype: 'catalog',
    sort: 99
  }
  // children: []
}
