import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterView = _resolveComponent("RouterView")!
  const _component_AppProvider = _resolveComponent("AppProvider")!
  const _component_NConfigProvider = _resolveComponent("NConfigProvider")!

  return (_openBlock(), _createBlock(_component_NConfigProvider, {
    "theme-overrides": _ctx.themeOverrides,
    locale: _ctx.zhCN,
    "date-locale": _ctx.dateZhCN
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AppProvider, null, {
        default: _withCtx(() => [
          _createVNode(_component_RouterView)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme-overrides", "locale", "date-locale"]))
}