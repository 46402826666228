import { renderIcon } from '@/utils/index'
import { FlashOutline } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/lead',
  name: 'lead',
  component: Layout,
  meta: {
    title: '线索管理',
    icon: renderIcon(FlashOutline),
    rtype: 'catalog',
    sort: 11
  }
  // children: []
}
