import qlRequest from '../index'
import { UserAPI } from '@/enums'
import { IDataType } from '@/types'

export function requestUpdateUser(userinfo) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUser,
    headers: { token: userinfo.token },
    data: userinfo,
    showLoading: true
  })
}

export function requestUpdatePwd(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.CheckPwd,
    data: param,
    showLoading: false
  })
}

export function requestUserList(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UserList,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestDownUserList(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.DownUserList,
    headers: { token: param.token },
    // responseType: 'blob',
    data: param,
    showLoading: false
  })
}

export function requestAddUser(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.AddUser,
    headers: { token: param.token },
    // responseType: 'blob',
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserRoles(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserRoles,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserRoles(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserRoles,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserBukrs(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserBukrs,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserBukrs(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserBukrs,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserVkorg(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserVkorg,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserVkorg(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserVkorg,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserVtweg(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserVtweg,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserVtweg(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserVtweg,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserSpart(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserSpart,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserSpart(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserSpart,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestUpdateUserWerks(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserWerks,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

export function requestGetUserWerks(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserWerks,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

// 更新用户OA 公司权限
export function requestUpdateUserOASubComp(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserOASubComp,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

//获取用户OA 公司权限
export function requestGetUserOASubComp(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserOASubComp,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

// 更新用户OA 部门权限
export function requestUpdateUserOADepart(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.UpdateUserOADepart,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}

//获取用户OA 部门权限
export function requestGetUserOADepart(param) {
  return qlRequest.post<IDataType>({
    url: UserAPI.GetUserOADepart,
    headers: { token: param.token },
    data: param,
    showLoading: false
  })
}
