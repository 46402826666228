import { renderIcon } from '@/utils/index'
import { AccessibilitySharp } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/bp',
  name: 'bp',
  component: Layout,
  meta: {
    title: '客户管理',
    icon: renderIcon(AccessibilitySharp),
    rtype: 'catalog',
    sort: 10
  }
  // children: []
}
