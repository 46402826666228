import { renderIcon } from '@/utils/index'
import { PersonSharp } from '@vicons/ionicons5'

export default {
  path: 'userinfo',
  name: 'usersetting_userinfo',
  component: () => import('@/views/userSetting/userInfo.vue'),
  meta: {
    title: 'My Info',
    icon: renderIcon(PersonSharp),
    rtype: 'item',
    sort: 1
  }
}
