import { renderIcon } from '@/utils/index'
import { FlashOutline } from '@vicons/ionicons5'

export default {
  path: 'lead03',
  name: 'lead_lead03',
  component: () => import('@/views/Lead/lead03.vue'),
  meta: {
    title: '线索查询',
    icon: renderIcon(FlashOutline),
    rtype: 'item',
    sort: 3
  }
}
