import { renderIcon } from '@/utils/index'
import { AnalyticsSharp } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/eaanaly',
  name: 'eaanaly',
  component: Layout,
  meta: {
    title: 'Analytics',
    icon: renderIcon(AnalyticsSharp),
    rtype: 'catalog',
    sort: 20
  }
  // children: []
}
