import { renderIcon } from '@/utils/index'
import { BarChartSharp } from '@vicons/ionicons5'

export default {
  path: 'eaanaly04',
  name: 'eaanaly_eaanaly04',
  component: () => import('@/views/eaAnaly/eaAnaly04.vue'),
  meta: {
    title: 'Rev Forecast',
    icon: renderIcon(BarChartSharp),
    rtype: 'item',
    sort: 2
  }
}
