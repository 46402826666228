import { RouteRecordRaw } from 'vue-router'

export function sortRoute(a, b) {
  return (a.meta?.sort || 0) - (b.meta?.sort || 0)
}

export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  // console.log(userMenus)

  const routes: RouteRecordRaw[] = []
  // const route: RouteRecordRaw = {}

  // 1.加载默认所有的routes
  const allChildRoutes: RouteRecordRaw[] = []
  const routeChildFiles = require.context('@/router/modules', true, /\.ts/)
  routeChildFiles.keys().forEach((key) => {
    // console.log(key)
    const proute = require('@/router/modules' + key.split('.')[1])
    // console.log(route)
    allChildRoutes.push(proute.default)
  })
  // console.log(allChildRoutes)

  const allParentRoutes: RouteRecordRaw[] = []
  const routeParentFiles = require.context('@/router/index', false, /\.ts/)
  routeParentFiles.keys().forEach((key) => {
    // console.log(key)
    const croute = require('@/router/index' + key.split('.')[1])
    // console.log(route)
    allParentRoutes.push(croute.default)
  })
  // console.log(allParentRoutes)

  // 2.根据菜单获取需要添加的routes
  for (const menu of userMenus) {
    // debugger
    if (menu.rtype === 'catalog' && menu.children && menu.children.length > 0) {
      const proute = allParentRoutes.find((route) => route.name === menu.name)
      if (proute) {
        // routes.push(proute)
        // console.log(proute)
        proute.children = []

        for (const child of menu.children) {
          // debugger
          // console.log(child)
          const croute = allChildRoutes.find(
            (croute) => croute.name === child.name
          )
          // console.log(croute)
          if (croute) proute.children.push(croute)
          // console.log(proute)
          routes.push(proute)
        }
      }
    }
  }

  // console.log(routes)

  return routes
}
