import { renderIcon } from '@/utils/index'
import { HelpBuoySharp } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/system',
  name: 'system',
  component: Layout,
  meta: {
    title: '系统设置',
    icon: renderIcon(HelpBuoySharp),
    rtype: 'catalog',
    sort: 98
  }
  // children: []
}
