import { renderIcon } from '@/utils/index'
import { PersonSharp } from '@vicons/ionicons5'

const user = () => import('@/views/system/user.vue')
export default {
  path: 'user',
  name: 'system_user',
  component: user,
  meta: {
    title: '用户管理(角色)',
    icon: renderIcon(PersonSharp),
    rtype: 'item',
    sort: 4
  }
}
