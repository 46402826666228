import { IDataType } from '@/types'
import { param2Obj } from '@/utils'

const token: IDataType = {
  returnCode: 0,
  returnMsg: '成功',
  data: {
    userid: 'shaoming.lu',
    username: '鲁韶鸣',
    token:
      'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NCwibmFtZSI6ImNvZGVydGVzdCIsInJvbGUiOnsiaWQiOjMsIm5hbWUiOiLov5DokKUifSwiaWF0IjoxNjMzNzYxMjIzLCJleHAiOjE2MzYzNTMyMjN9.V__p5rkVE1J3KcQlIX2v4s-GZJEhD_rwpXxRtgVwXc4DL3HTkufECRMM0R8ThNafD6tF11eUNNOkyFfhhsMP_6J5sx4ToAwe4wRINFCSapqOVrP0SMqZK3sFFTgE9y8q73bivwo-q8SAWOo6nRB4dMuKOx5NcjyLvrGO4MVBbEc'
  }
}

const userinfo: IDataType = {
  returnCode: 0,
  returnMsg: '成功',
  data: {
    userid: 'shaoming.lu',
    username: '鲁韶鸣',
    depart: 'IT部门',
    position: '开发工程师',
    phone: '110',
    email: 'lsm2002lsm@163.com',
    create_at: '2021-10-19T09:58:04Z',
    update_at: '2021-10-19T09:58:04Z',
    is_del: false,
    is_lock: false
  }
}

const checkTokenResp: IDataType = {
  returnCode: 0,
  returnMsg: '成功',
  data: null
}

const userMenus: IDataType = {
  returnCode: 0,
  returnMsg: '成功',
  data: [
    {
      id: 2,
      name: 'system',
      rtype: 'catalog',
      sort: 2,
      children: [
        {
          id: 21,
          name: 'system_menu',
          rtype: 'item',
          children: null
        },
        {
          id: 22,
          name: 'system_role',
          rtype: 'item',
          children: null
        },
        {
          id: 23,
          name: 'system_user',
          rtype: 'item',
          children: null
        },
        {
          id: 24,
          name: 'system_department',
          rtype: 'item',
          children: null
        }
      ]
    },
    {
      id: 99,
      name: 'usersetting',
      rtype: 'catalog',
      sort: 99,
      children: [
        {
          id: 991,
          name: 'usersetting_userinfo',
          rtype: 'item',
          children: null
        },
        {
          id: 992,
          name: 'usersetting_userpassword',
          rtype: 'item',
          children: null
        }
      ]
    }
  ]
}
export default {
  getToken: (config) => {
    // const { name, password } = JSON.parse(config.body)
    // console.log(name, password)

    return token
  },
  getUserInfo: (config) => {
    // const id = param2Obj(config.url)
    // console.log(id)
    return userinfo

    // if (userinfo.data[id]) {
    //   return userinfo[id]
    // } else {
    //   return false
    // }
  },
  getUserMenus: (config) => {
    // const id = param2Obj(config.url)
    // console.log(id)
    return userMenus
  },
  checkToken: (config) => {
    return checkTokenResp
  }
  // logout: () => 'success'
}

// const userMenus: IDataType = {
//   returnCode: 0,
//   returnMsg: '成功',
//   data: [
//     {
//       id: 2,
//       name: 'system',
//       rtype: 'catalog',
//       sort: 2,
//       children: [
//         {
//           id: 21,
//           name: 'system_menu',
//           rtype: 'item',
//           children: null
//         },
//         {
//           id: 22,
//           name: 'system_role',
//           rtype: 'item',
//           children: null
//         },
//         {
//           id: 23,
//           name: 'system_user',
//           rtype: 'item',
//           children: null
//         },
//         {
//           id: 24,
//           name: 'system_department',
//           rtype: 'item',
//           children: null
//           // [
//           //   {
//           //     id: 26,
//           //     url: null,
//           //     name: '创建角色',
//           //     sort: null,
//           //     type: 3,
//           //     parentId: 25,
//           //     permission: 'system:role:create'
//           //   },
//           //   {
//           //     id: 27,
//           //     url: null,
//           //     name: '删除角色',
//           //     sort: null,
//           //     type: 3,
//           //     parentId: 25,
//           //     permission: 'system:role:delete'
//           //   },
//           //   {
//           //     id: 28,
//           //     url: null,
//           //     name: '修改角色',
//           //     sort: null,
//           //     type: 3,
//           //     parentId: 25,
//           //     permission: 'system:role:update'
//           //   },
//           //   {
//           //     id: 29,
//           //     url: null,
//           //     name: '查询角色',
//           //     sort: null,
//           //     type: 3,
//           //     parentId: 25,
//           //     permission: 'system:role:query'
//           //   }
//           // ],
//         }
//       ]
//     }
//   ]
// }
