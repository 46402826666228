
import { defineComponent, computed } from 'vue'
import { AppProvider } from '@/components/Application'
import { lighten } from '@/utils/index'
import { APP_THEME } from '@/enums'
import { zhCN, dateZhCN, GlobalThemeOverrides } from 'naive-ui'

export default defineComponent({
  name: 'App',
  components: { AppProvider },
  setup() {
    // 重写主题颜色
    // const appTheme = '#0084f4'
    const lightenStr = lighten(APP_THEME, 10)

    const themeOverrides = {
      common: {
        primaryColor: APP_THEME,
        primaryColorHover: lightenStr,
        primaryColorPressed: lightenStr,
        // fontWeightStrong: '600'
        fontSize: '12px'
      },
      LoadingBar: {
        colorLoading: APP_THEME
      }
    }

    return {
      themeOverrides,
      zhCN,
      dateZhCN
    }
  }
})
