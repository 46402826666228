import { renderIcon } from '@/utils/index'
import { BarChartSharp } from '@vicons/ionicons5'

export default {
  path: 'eaanaly02',
  name: 'eaanaly_eaanaly02',
  component: () => import('@/views/eaAnaly/eaAnaly02.vue'),
  meta: {
    title: 'Analytics02',
    icon: renderIcon(BarChartSharp),
    rtype: 'item',
    sort: 3
  }
}
