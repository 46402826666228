// 根据process.env.NODE_ENV区分
// 开发环境: development
// 生成环境: production
// 测试环境: test

let BASE_URL: string | undefined = ''
//单位毫秒
const TIME_OUT = 10 * 10 * 60 * 2500 // 25分钟

// if (process.env.NODE_ENV === 'development') {
//   BASE_URL = '/api'
//   BASE_URL = 'http://wwww.123.com/dev'
// } else if (process.env.NODE_ENV === 'production') {
//   BASE_URL = 'http://www.123.com/prod'
// } else {
//   BASE_URL = 'http://www.123.com/test'
// }

BASE_URL = process.env.VUE_APP_API
export { BASE_URL, TIME_OUT }
