import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DialogContent = _resolveComponent("DialogContent")!
  const _component_MessageContent = _resolveComponent("MessageContent")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_dialog_provider = _resolveComponent("n-dialog-provider")!
  const _component_n_loading_bar_provider = _resolveComponent("n-loading-bar-provider")!

  return (_openBlock(), _createBlock(_component_n_loading_bar_provider, null, {
    default: _withCtx(() => [
      _createVNode(_component_n_dialog_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_DialogContent),
          _createVNode(_component_n_notification_provider, null, {
            default: _withCtx(() => [
              _createVNode(_component_n_message_provider, null, {
                default: _withCtx(() => [
                  _createVNode(_component_MessageContent),
                  _renderSlot(_ctx.$slots, "default", { slot: "default" })
                ]),
                _: 3
              })
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}