import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { HomeSharp, ColorPaletteSharp, GlobeSharp } from '@vicons/ionicons5'
import { renderIcon } from '@/utils/index'
import LocalCache from '@/utils/localCacheStorage'
import { TOKEN_KEY } from '@/enums'

const Layout = () => import('@/views/main/index.vue')

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Root',
    // redirect: PageEnum.BASE_HOME,
    redirect: '/dashboard',
    // redirect: '/dashboard/console',
    meta: {
      title: 'Root',
      rtype: 'root'
    }
  },

  {
    path: '/dashboard',
    name: 'dashboard',
    redirect: '/dashboard/home',
    component: Layout,
    meta: {
      title: 'HOME',
      icon: renderIcon(HomeSharp),
      rtype: 'catalog',
      sort: 0
    },
    children: [
      {
        path: 'home',
        name: 'dashboard_home',
        component: () => import('@/views/dashboard/home.vue'),
        meta: {
          title: 'home',
          icon: renderIcon(GlobeSharp),
          rtype: 'item'
        }
      },
      {
        path: 'workplace',
        name: 'dashboard_workplace',
        component: () => import('@/views/dashboard/workplace.vue'),
        meta: {
          title: 'Calendar',
          icon: renderIcon(ColorPaletteSharp),
          rtype: 'item'
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue'),
    meta: {
      title: '登录',
      rtype: 'login'
    }
  },

  {
    // path: '/:pathMatch(.*)*',
    path: '/:path(.*)*',
    name: 'notFound',
    component: Layout,
    meta: {
      title: 'ErrorPage'
    },
    children: [
      {
        path: '/:path(.*)*',
        name: 'ErrorPageSon',
        component: () => import('@/views/not-found/not-found.vue'),
        meta: {
          title: 'ErrorPage'
        }
      }
    ]
  },

  // test 大屏
  {
    path: '/eaanaly/eaanaly0101',
    name: 'eaanaly0101',
    component: () => import('@/views//eaAnaly/eaAnaly0101.vue')
  }
]

//
const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

//路由守卫

router.beforeEach(async (to) => {
  if (to.path !== '/login') {
    const token = LocalCache.getCache(TOKEN_KEY)
    if (!token) {
      return '/login'
    }
  }

  // console.log(router.getRoutes())
  // console.log(to)
})

export default router
