import { renderIcon } from '@/utils/index'
import { MenuSharp } from '@vicons/ionicons5'

const menu = () => import('@/views/system/menu.vue')
export default {
  path: 'menu',
  name: 'system_menu',
  component: menu,
  meta: {
    title: '菜单管理',
    icon: renderIcon(MenuSharp),
    rtype: 'item',
    sort: 1
  }
}
