import { renderIcon } from '@/utils/index'
import { GitNetworkOutline } from '@vicons/ionicons5'

const Layout = () => import('@/views/main/index.vue')
export default {
  path: '/optunity',
  name: 'optunity',
  component: Layout,
  meta: {
    title: 'Pipeline&Source',
    icon: renderIcon(GitNetworkOutline),
    rtype: 'catalog',
    sort: 12
  }
  // children: []
}
