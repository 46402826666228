import { IDataType } from '@/types'

const Success: IDataType = {
  returnCode: 0,
  returnMsg: '成功',
  data: null
}

export default {
  updateUserInfo: (config) => {
    // const { name, password } = JSON.parse(config.body)
    // console.log(name, password)

    return Success
  }
}
