
import { defineComponent } from 'vue'
import {
  NDialogProvider,
  NNotificationProvider,
  NMessageProvider,
  NLoadingBarProvider
} from 'naive-ui'
import { MessageContent } from '@/components/MessageContent'
import { DialogContent } from '@/components/DialogContent'

export default defineComponent({
  name: 'Application',
  components: {
    NDialogProvider,
    NNotificationProvider,
    NMessageProvider,
    NLoadingBarProvider,
    MessageContent,
    DialogContent
  },
  setup() {
    return {}
  }
})
