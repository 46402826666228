import { renderIcon } from '@/utils/index'
import { LockClosedSharp } from '@vicons/ionicons5'

export default {
  path: 'userpassword',
  name: 'usersetting_userpassword',
  component: () => import('@/views/userSetting/userPassword.vue'),
  meta: {
    title: 'Change Password',
    icon: renderIcon(LockClosedSharp),
    rtype: 'item',
    sort: 2
  }
}
