import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'

const DATE_FORMAT = 'YYYY-MM-DD'
const YEAR_FORMAT = 'YYYY'

const TIME_FORMAT = 'HH:mm:ss'

export function formatUtcString(
  utcString: string,
  format: string = DATE_TIME_FORMAT
) {
  return dayjs.utc(utcString).utcOffset(8).format(format)
}

export function formatDateString(
  datetime: number,
  format: string = DATE_FORMAT
) {
  return dayjs(datetime).format(format)
}

export function formatTimeString(
  datetime: number,
  format: string = TIME_FORMAT
) {
  return dayjs(datetime).format(format)
}

export function formatYearString(year: number, format: string = YEAR_FORMAT) {
  return dayjs(year).format(format)
}
