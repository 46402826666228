import { renderIcon } from '@/utils/index'
import { AccessibilitySharp } from '@vicons/ionicons5'

export default {
  path: 'bp03',
  name: 'bp_bp03',
  component: () => import('@/views/BP/bp03.vue'),
  meta: {
    title: '我的客户清单',
    icon: renderIcon(AccessibilitySharp),
    rtype: 'item',
    sort: 3
  }
}
