import { renderIcon } from '@/utils/index'
import { BarChartSharp } from '@vicons/ionicons5'

export default {
  path: 'eaanaly01',
  name: 'eaanaly_eaanaly01',
  component: () => import('@/views/eaAnaly/eaAnaly01.vue'),
  meta: {
    title: 'Analytics-DP',
    icon: renderIcon(BarChartSharp),
    rtype: 'item',
    sort: 4
  }
}
