import { renderIcon } from '@/utils/index'
import { AccessibilitySharp } from '@vicons/ionicons5'

export default {
  path: 'bp02',
  name: 'bp_bp02',
  component: () => import('@/views/BP/bp02.vue'),
  meta: {
    title: '客户修改',
    icon: renderIcon(AccessibilitySharp),
    rtype: 'item',
    sort: 2
  }
}
