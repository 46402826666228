import { renderIcon } from '@/utils/index'
import { FlashOutline } from '@vicons/ionicons5'

export default {
  path: 'lead02',
  name: 'lead_lead02',
  component: () => import('@/views/Lead/lead02.vue'),
  meta: {
    title: '修改线索',
    icon: renderIcon(FlashOutline),
    rtype: 'item',
    sort: 2
  }
}
