import { Module } from 'vuex'

import { IRootState, ILoginState, IAccount, IUserToken } from '@/types'
import {
  accountLoginRequest,
  requestUserInfo,
  requestUserMenus,
  requestUserToken
} from '@/service/login/login'

import {
  requestGetUserBukrs,
  requestGetUserVkorg,
  requestGetUserVtweg,
  requestGetUserSpart,
  requestGetUserWerks,
  requestGetUserOASubComp,
  requestGetUserOADepart
} from '@/service/user/user'

import LocalCache from '@/utils/localCacheStorage'
import router from '@/router'
import { mapMenusToRoutes } from '@/utils/map-menus'
import { TOKEN_KEY, USERINFO_KEY, USERMENUS_KEY, USERAUTH_KEY } from '@/enums'

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      token: '',
      userInfo: {},
      userMenus: [],
      userAuth: {}
    }
  },
  getters: {},
  mutations: {
    //修改 state 的唯一方式 使用 mutations
    changeToken(state, token: string) {
      state.token = token
    },

    changeUserInfo(state, userInfo: any) {
      state.userInfo = userInfo
    },

    changeUserMenus(state, userMenus: any) {
      state.userMenus = userMenus

      // console.log('注册动态路由')
      // // userMenus => routes
      const routes = mapMenusToRoutes(userMenus)
      routes.forEach((route) => {
        router.addRoute(route)
      })
    },

    changeUserAuth(state, userAuth: any) {
      state.userAuth = userAuth
    },
    logout(state) {
      state.token = ''
      state.userInfo = {}
      state.userMenus = []
      state.userAuth = {}
      LocalCache.clearCache()
    }
  },
  actions: {
    async accountLoginAction({ commit }, playload: IAccount) {
      // 1 action 实现异步 登录
      try {
        const loginResponse = await accountLoginRequest(playload)
        // console.log(loginResponse)
        if (loginResponse.returnCode === 0) {
          const { token } = loginResponse.data
          commit('changeToken', token)
          LocalCache.setCache(TOKEN_KEY, token)
        }
        return Promise.resolve(loginResponse)
      } catch (e) {
        return Promise.reject(e)
      }
    },
    async getUserInfoAction({ commit }, playload: IUserToken) {
      // 2 action 实现异步 获取用户信息
      try {
        const userInfoResponse = await requestUserInfo(playload)
        if (userInfoResponse.returnCode === 0) {
          const userInfo = userInfoResponse.data
          commit('changeUserInfo', userInfo)
          LocalCache.setCache(USERINFO_KEY, userInfo)
        }
        return Promise.resolve(userInfoResponse)
      } catch (e) {
        return Promise.reject(e)
      }
      // console.log(playload)
    },

    async getUserMenusAction({ commit }, playload: IUserToken) {
      // 3 action 实现异步 获取菜单
      try {
        // console.log(playload)
        const userMenusResponse = await requestUserMenus(playload)
        if (userMenusResponse.returnCode === 0) {
          const userMenus = userMenusResponse.data
          commit('changeUserMenus', userMenus)
          LocalCache.setCache(USERMENUS_KEY, userMenus)
        }
        return Promise.resolve(userMenusResponse)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    async getUserAuthAction({ commit }, playload: IUserToken) {
      // 4 action 实现异步 获取用户权限
      try {
        const user_auth: any = {}
        // 公司代码
        const user_bukrs: any = []
        const getUserBukrsResp = await requestGetUserBukrs(playload)
        if (getUserBukrsResp.returnCode === 0) {
          getUserBukrsResp.data.forEach((v, i) => {
            user_bukrs.push({
              bukrs: v.bukrs,
              butxt: v.butxt
            })
          })
        }
        // console.log(user_bukrs)
        user_auth['bukrs'] = user_bukrs

        // 销售组织
        const user_vkorg: any = []
        const getUserVkorgResp = await requestGetUserVkorg(playload)
        if (getUserVkorgResp.returnCode === 0) {
          getUserVkorgResp.data.forEach((v, i) => {
            user_vkorg.push({
              vkorg: v.vkorg,
              vtext: v.vtext
            })
          })
        }
        user_auth['vkorg'] = user_vkorg

        // 分销渠道
        const user_vtweg: any = []
        const getUserVtwegResp = await requestGetUserVtweg(playload)
        if (getUserVtwegResp.returnCode === 0) {
          getUserVtwegResp.data.forEach((v, i) => {
            user_vtweg.push({
              vtweg: v.vtweg,
              vtext: v.vtext
            })
          })
        }
        user_auth['vtweg'] = user_vtweg

        // 产品组
        const user_spart: any = []
        const getUserSpartResp = await requestGetUserSpart(playload)
        if (getUserSpartResp.returnCode === 0) {
          getUserSpartResp.data.forEach((v, i) => {
            user_spart.push({
              spart: v.vtweg,
              vtext: v.vtext
            })
          })
        }
        user_auth['spart'] = user_spart

        // 工厂
        const user_werks: any = []
        const getUserWerksResp = await requestGetUserWerks(playload)
        if (getUserWerksResp.returnCode === 0) {
          getUserWerksResp.data.forEach((v, i) => {
            user_werks.push({
              werks: v.werks,
              name1: v.name1,
              name2: v.name2
            })
          })
        }
        user_auth['werks'] = user_werks

        // OA 公司
        const user_subcompany: any = []
        const getUserSubcompanyResp = await requestGetUserOASubComp(playload)
        if (getUserSubcompanyResp.returnCode === 0) {
          getUserSubcompanyResp.data.forEach((v, i) => {
            user_subcompany.push({
              subcompanyid1: v.subcompanyid1,
              subcompanyname: v.subcompanyname
            })
          })
        }
        user_auth['oa_subcompany'] = user_subcompany

        // OA 部门
        const user_depart: any = []
        const getUserDepartResp = await requestGetUserOADepart(playload)
        if (getUserDepartResp.returnCode === 0) {
          getUserDepartResp.data.forEach((v, i) => {
            user_depart.push({
              departmentid: v.departmentid,
              departmentname: v.departmentname
            })
          })
        }
        user_auth['oa_depart'] = user_depart

        // console.log(user_auth)
        commit('changeUserAuth', user_auth)
        LocalCache.setCache(USERAUTH_KEY, user_auth)
        return true
      } catch (e) {
        return Promise.reject(e)
      }
    },

    // 检测token有效性
    async checkUserToken({ commit }, playload: IUserToken) {
      try {
        // console.log(playload)
        const userToeknResponse = await requestUserToken(playload)
        return Promise.resolve(userToeknResponse)
      } catch (e) {
        return Promise.reject(e)
      }
    },

    loadLocalLogin({ commit }) {
      const token = LocalCache.getCache(TOKEN_KEY)
      if (token) {
        commit('changeToken', token)
      }

      const userInfo = LocalCache.getCache(USERINFO_KEY)
      if (userInfo) {
        commit('changeUserInfo', userInfo)
      }

      const userMenus = LocalCache.getCache(USERMENUS_KEY)
      if (userMenus) {
        commit('changeUserMenus', userMenus)
      }

      const userAuth = LocalCache.getCache(USERAUTH_KEY)
      if (userAuth) {
        commit('changeUserAuth', userAuth)
      }
    },

    ActionLogout({ commit }) {
      commit('logout')
    }
  }
}

export default loginModule
