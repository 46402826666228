/***********************************************************************************
 *
 *登录接口api
 *
 ***********************************************************************************
 */

export enum LoginAPI {
  AccountLogin = '/user/token',
  LoginUserInfo = '/user/get_user',
  UserMenus = '/user/user_menus',
  CheckToken = '/user/check_token'
}

/***********************************************************************************
 *
 *用户接口接口api
 *
 ***********************************************************************************
 */

export enum UserAPI {
  AddUser = '/user/add_user',
  UpdateUser = '/user/update_user',
  CheckPwd = '/user/update_pwd',
  UserList = '/user/user_list',
  DownUserList = '/user/down_user_list',
  UpdateUserRoles = '/user/add_user_roles',
  GetUserRoles = '/user/get_user_roles',

  UpdateUserBukrs = '/user/add_user_bukrs',
  GetUserBukrs = '/user/get_user_bukrs',

  UpdateUserVkorg = '/user/add_user_vkorg',
  GetUserVkorg = '/user/get_user_vkorg',

  UpdateUserVtweg = '/user/add_user_vtweg',
  GetUserVtweg = '/user/get_user_vtweg',

  UpdateUserSpart = '/user/add_user_spart',
  GetUserSpart = '/user/get_user_spart',

  UpdateUserWerks = '/user/add_user_werks',
  GetUserWerks = '/user/get_user_werks',

  UpdateUserOASubComp = '/user/add_user_oa_subcomp',
  GetUserOASubComp = '/user/get_user_oa_subcomp',
  UpdateUserOADepart = '/user/add_user_oa_depart',
  GetUserOADepart = '/user/get_user_oa_depart'
}

/***********************************************************************************
 *
 *菜单接口api
 *
 ***********************************************************************************
 */

export enum MenusAPI {
  SaveMeuns = '/menu/save_menu',
  GetMenus = '/menu/get_menu'
}

/***********************************************************************************
 *
 *角色接口api
 *
 ***********************************************************************************
 */

export enum RoleAPI {
  AddRole = '/role/add_role',
  GetRoles = '/role/get_roles',
  GetRoleDetail = '/role/get_role_items',
  DeleteRole = '/role/delete_role',
  UpdateRole = '/role/update_role'
}

/***********************************************************************************
 *
 *组织数据接口api
 *
 ***********************************************************************************
 */

export enum OrgsAPI {
  GetT001 = '/orgs/get_t001',
  GetTvkot = '/orgs/get_tvkot',
  GetTvtwt = '/orgs/get_tvtwt',
  GetTspat = '/orgs/get_tspat',
  GetT001w = '/orgs/get_t001w',
  GetT024d = '/orgs/get_t024d',
  GetOASubcompany = '/orgs/get_oa_subcompany',
  GetOADepart = '/orgs/get_oa_depart'
}

/***********************************************************************************
 *
 *全局常量
 *
 ***********************************************************************************
 */
// localCache
export const TOKEN_KEY = 'TOKEN'

export const USERINFO_KEY = 'USERINFO'

export const USERMENUS_KEY = 'USERMENUS'

export const USERAUTH_KEY = 'USERAUTH'

// 系统主题颜色
export const APP_THEME = '#0084f4'

// 客户端类型
// 100 pc
// 101 mobile
export const CLIENT_TYPE = 100
