import { renderIcon } from '@/utils/index'
import { FlashOutline } from '@vicons/ionicons5'

export default {
  path: 'lead01',
  name: 'lead_lead01',
  component: () => import('@/views/Lead/lead01.vue'),
  meta: {
    title: '创建线索',
    icon: renderIcon(FlashOutline),
    rtype: 'item',
    sort: 1
  }
}
