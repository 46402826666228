import { renderIcon } from '@/utils/index'
import { PersonSharp } from '@vicons/ionicons5'

const user_auth = () => import('@/views/system/user_auth.vue')
export default {
  path: 'user_auth',
  name: 'system_user_auth',
  component: user_auth,
  meta: {
    title: '用户管理(权限)',
    icon: renderIcon(PersonSharp),
    rtype: 'item',
    sort: 3
  }
}
