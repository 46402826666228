// service统一出口
import QLRequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'

const qlRequest = new QLRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      // const token = ''
      // if (token) {
      //   config.headers!.Authorization = `Bearer ${token}`
      // }
      // console.log('请求成功的拦截')
      return config
    },
    requestInterceptorCatch: (err) => {
      // console.log('请求失败的拦截', err)
      return err
    },
    responseInterceptor: (res) => {
      // console.log('响应成功的拦截', res)
      return res
    },
    responseInterceptorCatch: (err) => {
      // console.log('响应失败的拦截', err)

      return err
    }
  }
})

export default qlRequest
