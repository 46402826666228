import Mock from 'mockjs'

import loginMock from './login'
import userMock from './user'
import roleMock from './role'

// import { LoginAPI, UserAPI, RoleAPI } from '@/enums'
import { LoginAPI, UserAPI } from '@/enums'

Mock.mock(
  `${process.env.VUE_APP_API}${LoginAPI.AccountLogin}`,
  'post',
  loginMock.getToken
)

Mock.mock(
  RegExp(`${process.env.VUE_APP_API}${LoginAPI.LoginUserInfo}` + '.*'),
  'post',
  loginMock.getUserInfo
)

Mock.mock(
  RegExp(`${process.env.VUE_APP_API}${LoginAPI.UserMenus}` + '.*'),
  'post',
  loginMock.getUserMenus
)

Mock.mock(
  RegExp(`${process.env.VUE_APP_API}${LoginAPI.CheckToken}` + '.*'),
  'post',
  loginMock.checkToken
)

// 更新用户信息
Mock.mock(
  RegExp(`${process.env.VUE_APP_API}${UserAPI.UpdateUser}` + '.*'),
  'post',
  userMock.updateUserInfo
)

// // 获取用户role
// Mock.mock(
//   RegExp(`${process.env.VUE_APP_API}${RoleAPI.UserRole}` + '.*'),
//   'post',
//   roleMock.getUserRole
// )
