import { renderIcon } from '@/utils/index'
import { GitNetworkOutline } from '@vicons/ionicons5'

export default {
  path: 'optunity01',
  name: 'optunity_optunity01',
  component: () => import('@/views/Opportunity/oprtunity01.vue'),
  meta: {
    title: 'Create Opportunity',
    icon: renderIcon(GitNetworkOutline),
    rtype: 'item',
    sort: 2
  }
}
