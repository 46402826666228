import { renderIcon } from '@/utils/index'
import { GitNetworkOutline } from '@vicons/ionicons5'

export default {
  path: 'optunity03',
  name: 'optunity_optunity03',
  component: () => import('@/views/Opportunity/oprtunity03.vue'),
  meta: {
    title: 'My Opportunities',
    icon: renderIcon(GitNetworkOutline),
    rtype: 'item',
    sort: 3
  }
}
