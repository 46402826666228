import { renderIcon } from '@/utils/index'
import { GitNetworkOutline } from '@vicons/ionicons5'

export default {
  path: 'optunity04',
  name: 'optunity_optunity04',
  component: () => import('@/views/Opportunity/oprtunity04.vue'),
  meta: {
    title: 'Opportunities',
    icon: renderIcon(GitNetworkOutline),
    rtype: 'item',
    sort: 4
  }
}
